import * as React from 'react';

type Props = {
  alt: string;
  src: string;
  width: number;
  height: number;
  loadImage: boolean;
};

export function UsecaseImage({ alt, src, width, height, loadImage }: Props) {
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      {!loadImage && <div style={{ width: '100%', height: '100%', background: '' }} />}
      {loadImage && (
        <img
          width={width}
          height={height}
          alt={alt}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
          src={src}
          loading="lazy"
        />
      )}
    </div>
  );
}
