import { AppVersionVo } from './app-version-vo';
import { TemplateModel } from './template-model';
import { TemplatePageVo } from './template-page-vo';
import { TemplateSizeCateoryEnum } from './template-size-category-enum';

export class MultiTemplateModel extends TemplateModel {
  constructor(
    id: number,
    author: string,
    uploaderInfo: string,
    sourceUrl: string,
    isVip: boolean,
    isAnimated: boolean,
    thumbnailUrl: string,
    animatedThumbnailUrl: string,
    sizeCategory: TemplateSizeCateoryEnum,
    slotsCount: number,
    createdAt: string,
    updatedAt: string,
    isMultipage: boolean,
    isAdjustable: boolean,
    lowerSuitablePhotosCount: number | null,
    upperSuitablePhotosCount: number | null,
    labels: string[],
    minimalRequiredVersionIos: AppVersionVo | null,
    minimalRequiredVersionAndroid: AppVersionVo | null,
    templateTagIds: number[],
    private pagesInfo: TemplatePageVo[],
  ) {
    super(
      id,
      author,
      uploaderInfo,
      sourceUrl,
      isVip,
      isAnimated,
      thumbnailUrl,
      animatedThumbnailUrl,
      sizeCategory,
      slotsCount,
      createdAt,
      updatedAt,
      isMultipage,
      isAdjustable,
      lowerSuitablePhotosCount,
      upperSuitablePhotosCount,
      labels,
      minimalRequiredVersionIos,
      minimalRequiredVersionAndroid,
      templateTagIds,
    );
  }

  public static create(
    id: number,
    author: string,
    uploaderInfo: string,
    sourceUrl: string,
    isVip: boolean,
    isAnimated: boolean,
    imageUrl: string,
    animatedImageUrl: string,
    sizeCategory: TemplateSizeCateoryEnum,
    slotsCount: number,
    createdAt: string,
    updatedAt: string,
    isMultipage: boolean,
    isAdjustable: boolean,
    lowerSuitablePhotosCount: number | null,
    upperSuitablePhotosCount: number | null,
    labels: string[],
    minimalRequiredVersionIos: AppVersionVo | null,
    minimalRequiredVersionAndroid: AppVersionVo | null,
    templateTagIds: number[],
    pagesInfo: TemplatePageVo[],
  ): MultiTemplateModel {
    return new MultiTemplateModel(
      id,
      author,
      uploaderInfo,
      sourceUrl,
      isVip,
      isAnimated,
      imageUrl,
      animatedImageUrl,
      sizeCategory,
      slotsCount,
      createdAt,
      updatedAt,
      isMultipage,
      isAdjustable,
      lowerSuitablePhotosCount,
      upperSuitablePhotosCount,
      labels,
      minimalRequiredVersionIos,
      minimalRequiredVersionAndroid,
      templateTagIds,
      pagesInfo,
    );
  }

  public static createMock(): MultiTemplateModel {
    return new MultiTemplateModel(
      0,
      '',
      '',
      '',
      false,
      false,
      '',
      '',
      TemplateSizeCateoryEnum.Square,
      0,
      '',
      '',
      false,
      false,
      null,
      null,
      [],
      null,
      null,
      [],
      [],
    );
  }

  public getPagesInfo(): TemplatePageVo[] {
    return this.pagesInfo;
  }

  public clone() {
    return new MultiTemplateModel(
      this.getId(),
      this.getAuthor(),
      this.getUploaderInfo(),
      this.getSourceUrl(),
      this.getIsVip(),
      this.getIsAnimated(),
      this.getThumbnailUrl(),
      this.getAnimatedThumbnailUrl(),
      this.getSizeCategory(),
      this.getSlotsCount(),
      this.getCreatedAt(),
      this.getUpdatedAt(),
      this.getIsMultipage(),
      this.getIsAdjustable(),
      this.getLowerSuitablePhotosCount(),
      this.getUpperSuitablePhotosCount(),
      this.getLabels(),
      this.getMinimalRequiredVersionIos(),
      this.getMinimalRequiredVersionAndroid(),
      this.getTemplateTagIds(),
      this.pagesInfo,
    );
  }
}
