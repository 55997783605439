/**
 * Preview version of InTagTemplateModel, this is to prevent
 * heavy load when we need to fetch a bunch of them in a page.
 */
export class PreviewInTagTemplateModel {
  constructor(
    private id: number,
    private isVip: boolean,
    private thumbnailUrl: string,
    private isAnimated: boolean,
    private isActive: boolean,
  ) {}

  static create(
    id: number,
    isVip: boolean,
    thumbnailUrl: string,
    isAnimated: boolean,
    isActive: boolean,
  ): PreviewInTagTemplateModel {
    return new PreviewInTagTemplateModel(id, isVip, thumbnailUrl, isAnimated, isActive);
  }

  public getId() {
    return this.id;
  }

  public getIsVip() {
    return this.isVip;
  }

  public getThumbnailUrl() {
    return this.thumbnailUrl;
  }

  public getIsAnimated() {
    return this.isAnimated;
  }

  public getIsActive() {
    return this.isActive;
  }
}
