import { parseSingleTemplateDto, SingleTemplateDto } from '../../common/dtos/single-template-dto';
import { parseMultiTemplateDto, MultiTemplateDto } from '../../common/dtos/multi-template-dto';
import { SingleTemplateModel } from '../../common/models/single-template-model';
import { MultiTemplateModel } from '../../common/models/multi-template-model';

export class TemplateApi {
  static create() {
    return new TemplateApi();
  }

  async fetchTemplatebyId(
    templateId: number,
  ): Promise<[error: Error | null, template: SingleTemplateModel | MultiTemplateModel]> {
    const response = await fetch(`/cms_api/templates/${templateId}`);

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return [new Error(error), SingleTemplateModel.createMock()];
    } else {
      const templateDto = (await response.json()) as SingleTemplateDto | MultiTemplateDto;
      let templateModel: SingleTemplateModel | MultiTemplateModel;
      if (templateDto.is_multipage) {
        templateModel = parseMultiTemplateDto(templateDto as MultiTemplateDto);
      } else {
        templateModel = parseSingleTemplateDto(templateDto as SingleTemplateDto);
      }
      return [null, templateModel];
    }
  }
}
