import * as React from 'react';
import {
  PicColor,
  PicFontSize,
  PicFontWeight,
  PicIcon,
  PicIconName,
  PicImage,
  PicTag,
  PicText,
} from '@cardinalblue/pic-collage-cms-ui';
import { InTagTemplateModel } from '../../common/models/in-tag-template-model';
import { PriceTag } from '../../common/components/tags/price-tag';
import { AccessTypeEnum } from '../../common/models/access-type-enum';

type Props = {
  template: InTagTemplateModel;
  selected?: boolean;
  idPosition?: 'top' | 'bottom';
  idFontWeight?: PicFontWeight;
  addedTemplateTagCount?: number;
  removedTemplateTagCount?: number;
  isLink: boolean;
  onClick?: () => void;
};

export function InTagTemplateCard({
  template,
  selected = false,
  idPosition = 'top',
  idFontWeight = PicFontWeight.Bold,
  addedTemplateTagCount = 0,
  removedTemplateTagCount = 0,
  isLink,
  onClick = () => {},
}: Props) {
  const hasAddedTemplateTags = addedTemplateTagCount > 0;
  const hasRemovedTemplateTags = removedTemplateTagCount > 0;

  const Wrapper = React.useMemo(
    () =>
      ({ children, style }: React.PropsWithChildren<{ style: React.CSSProperties }>): JSX.Element => {
        const templatePath = React.useMemo(() => `/templates/${template.getId()}/edit`, [template]);

        if (isLink) {
          return (
            <a
              key={template.getId()}
              href={isLink ? templatePath : undefined}
              target={isLink ? '_blank' : undefined}
              style={style}
            >
              {children}
            </a>
          );
        } else {
          return (
            <div key={template.getId()} style={style} onClick={onClick}>
              {children}
            </div>
          );
        }
      },
    [template, isLink, onClick],
  );

  return (
    <Wrapper
      style={{
        position: 'relative',
        borderRadius: '4px',
        padding: '8px',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        background: template.getIsActive() ? undefined : PicColor.Grey300,
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          display: idPosition === 'top' ? 'flex' : 'none',
          padding: '4px',
          flexFlow: 'row',
          justifyContent: 'center',
        }}
      >
        <PicText size={PicFontSize.Sm} weight={idFontWeight} align="center">
          {template.getId()}
        </PicText>
      </div>
      <div
        style={{
          position: 'absolute',
          top: '-4px',
          left: '-4px',
          width: 'calc(100% + 8px)',
          height: 'calc(100% + 8px)',
          border: selected ? `4px solid ${PicColor.Teal500}` : '',
          borderRadius: '8px',
        }}
      />
      <div style={{ position: 'relative', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
        <div style={{ position: 'absolute', top: '4px', left: '4px', display: 'flex', gap: '4px', zIndex: 1 }}>
          {template.getIsAnimated() && (
            <div
              style={{
                width: '28px',
                height: '28px',
                borderRadius: '50%',
                background: PicColor.Grey500,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PicIcon name={PicIconName.Animation} color={PicColor.White} size="24px" />
            </div>
          )}
        </div>
        <div
          style={{
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          }}
        >
          <PicImage src={template.getImageUrl()} width="100%" />
        </div>
        <div style={{ position: 'absolute', bottom: '4px', right: '4px' }}>
          {template.getIsVip() && <PriceTag accessType={AccessTypeEnum.Subscription} />}
        </div>
      </div>
      <div
        style={{
          display: idPosition === 'bottom' ? 'flex' : 'none',
          padding: '4px',
          flexFlow: 'row',
          justifyContent: 'center',
        }}
      >
        <PicText size={PicFontSize.Sm} weight={idFontWeight} align="center">
          {template.getId()}
        </PicText>
      </div>
      {(hasAddedTemplateTags || hasRemovedTemplateTags) && (
        <div style={{ flexGrow: 1, padding: '4px', display: 'flex', flexFlow: 'column', gap: '4px' }}>
          {hasAddedTemplateTags && (
            <div>
              <PicTag copy={`+ ${addedTemplateTagCount} tags`} color={PicColor.Teal700} />
            </div>
          )}
          {hasRemovedTemplateTags && (
            <div>
              <PicTag copy={`- ${removedTemplateTagCount} tags`} color={PicColor.Red500} />
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
}

export const MemoizedInTagTemplateCard = React.memo(InTagTemplateCard);
