export class TemplatePageVo {
  constructor(
    private readonly templateId: number,
    private readonly templateThumbnailUrl: string,
  ) {}

  static create(templateId: number, templateThumbnailUrl: string): TemplatePageVo {
    return new TemplatePageVo(templateId, templateThumbnailUrl);
  }

  public getTemplateId(): number {
    return this.templateId;
  }

  public getTemplateThumbnailUrl(): string {
    return this.templateThumbnailUrl;
  }
}
