import { deduplicateArray } from '../utils/array';
import { TemplateSizeCateoryEnum } from './template-size-category-enum';

/**
 * InTagTemplateModel is used when we fetch templates under a template tag,
 * because it has some fields with logic related to its parent template tag, so we have to
 * separate this from our TemplateModel.
 */
export class InTagTemplateModel {
  constructor(
    private id: number,
    private isVip: boolean,
    private imageUrl: string,
    private isAnimated: boolean,
    private isActive: boolean,
    private sizeCategory: TemplateSizeCateoryEnum,
    private slotsCount: number,
    private createdAt: string,
    private updatedAt: string,
    private templateTagIds: number[],
  ) {}

  static create(
    id: number,
    isVip: boolean,
    imageUrl: string,
    isAnimated: boolean,
    isActive: boolean,
    sizeCategory: TemplateSizeCateoryEnum,
    slotsCount: number,
    createdAt: string,
    updatedAt: string,
    templateTagIds: number[],
  ): InTagTemplateModel {
    return new InTagTemplateModel(
      id,
      isVip,
      imageUrl,
      isAnimated,
      isActive,
      sizeCategory,
      slotsCount,
      createdAt,
      updatedAt,
      templateTagIds,
    );
  }

  public getId() {
    return this.id;
  }

  public getIsVip() {
    return this.isVip;
  }

  public getImageUrl() {
    return this.imageUrl;
  }

  public getIsAnimated() {
    return this.isAnimated;
  }

  public getIsActive() {
    return this.isActive;
  }

  public updateIsActive(isActive: boolean) {
    this.isActive = isActive;
  }

  public getSizeCategory() {
    return this.sizeCategory;
  }

  public getSlotsCount() {
    return this.slotsCount;
  }

  public getCreatedAt() {
    return this.createdAt;
  }

  public getUpdatedAt() {
    return this.updatedAt;
  }

  public getTemplateTagIds() {
    return this.templateTagIds;
  }

  public addTemplateTagIds(templateTagIds: number[]) {
    const newTemplateTagIds = deduplicateArray(
      this.getTemplateTagIds().concat(templateTagIds),
      (tagId: number) => tagId,
    );
    this.templateTagIds = newTemplateTagIds;
  }

  public removeTemplateTagIds(templateTagIds: number[]) {
    const newTemplateTagIds = this.getTemplateTagIds().filter((tagId) => !templateTagIds.includes(tagId));
    this.templateTagIds = newTemplateTagIds;
  }

  public clone() {
    return new InTagTemplateModel(
      this.id,
      this.isVip,
      this.imageUrl,
      this.isAnimated,
      this.isActive,
      this.sizeCategory,
      this.slotsCount,
      this.createdAt,
      this.updatedAt,
      this.templateTagIds,
    );
  }
}
