import { PreviewInTagTemplateModel } from '../models/preview-in-tag-template-model';

export type PreviewInTagTemplateDto = {
  id: number;
  is_vip: boolean;
  image_url: string;
  is_animated: boolean;
  is_active: boolean;
};

export const parsePreviewInTagTemplateDto = (dto: PreviewInTagTemplateDto): PreviewInTagTemplateModel => {
  return PreviewInTagTemplateModel.create(dto.id, dto.is_vip, dto.image_url, dto.is_animated, dto.is_active);
};
