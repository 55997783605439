import * as React from 'react';
import {
  PicColor,
  PicDivider,
  PicFontSize,
  PicFontWeight,
  PicLink,
  PicModal,
  PicTag,
  PicText,
} from '@cardinalblue/pic-collage-cms-ui';
import { InTagTemplateModel } from '../../common/models/in-tag-template-model';
import { TemplateTagModel } from '../../common/models/template-tag-model';
import { InTagTemplateCard } from './in-tag-template-card';
import { getAddedItemsInArray, getRemovedItemsInArray } from '../../common/utils/array';

type TemplateBulkTaggingMap = Record<
  number,
  { templateTag: TemplateTagModel; addedTemplates: InTagTemplateModel[]; removedTemplates: InTagTemplateModel[] }
>;

type Props = {
  opened: boolean;
  templateTags: TemplateTagModel[];
  sourceTemplates: InTagTemplateModel[];
  draftTemplates: InTagTemplateModel[];
  onRevertAddTemplateTags: (templateTagId: number, templateIds: number[]) => void;
  onRevertRemoveTemplateTags: (templateTagId: number, templateIds: number[]) => void;
  onClose: () => void;
};

export function CheckAddedRemovedTemplateTagsModal({
  opened,
  templateTags,
  sourceTemplates,
  draftTemplates,
  onRevertAddTemplateTags,
  onRevertRemoveTemplateTags,
  onClose,
}: Props) {
  const templateTagMap = React.useMemo(
    () =>
      templateTags.reduce<Record<number, TemplateTagModel>>((prev, curr) => {
        prev[curr.getId()] = curr;
        return prev;
      }, {}),
    [templateTags],
  );

  const getSourceTemplate = React.useCallback(
    (templateId: number) => {
      return sourceTemplates.find((template) => template.getId() === templateId) || null;
    },
    [sourceTemplates],
  );

  const templateBulkTaggingMap: TemplateBulkTaggingMap = React.useMemo(() => {
    const result: TemplateBulkTaggingMap = {};

    draftTemplates.forEach((draftTemplate) => {
      const sourceTemplate = getSourceTemplate(draftTemplate.getId());
      if (!sourceTemplate) return;

      const addedTemplateTagIds = getAddedItemsInArray(
        sourceTemplate.getTemplateTagIds(),
        draftTemplate.getTemplateTagIds(),
        (id) => id,
      );
      const removedTemplateTagIds = getRemovedItemsInArray(
        sourceTemplate.getTemplateTagIds(),
        draftTemplate.getTemplateTagIds(),
        (id) => id,
      );

      addedTemplateTagIds.forEach((addedTemplateTagId) => {
        if (!result[addedTemplateTagId]) {
          result[addedTemplateTagId] = {
            templateTag: templateTagMap[addedTemplateTagId],
            addedTemplates: [draftTemplate],
            removedTemplates: [],
          };
        } else {
          result[addedTemplateTagId].addedTemplates.push(draftTemplate);
        }
      });

      removedTemplateTagIds.forEach((removedTemplateTagId) => {
        if (!result[removedTemplateTagId]) {
          result[removedTemplateTagId] = {
            templateTag: templateTagMap[removedTemplateTagId],
            addedTemplates: [],
            removedTemplates: [draftTemplate],
          };
        } else {
          result[removedTemplateTagId].removedTemplates.push(draftTemplate);
        }
      });
    });

    return result;
  }, [getSourceTemplate, draftTemplates, sourceTemplates, templateTagMap]);

  const templateBulkTaggingEntries = Object.entries(templateBulkTaggingMap);

  return (
    <PicModal opened={opened} onBackgroundClick={onClose} onCloseClick={onClose}>
      <div
        style={{
          position: 'relative',
          width: '800px',
          maxHeight: '600px',
          background: PicColor.White,
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <div
          style={{
            padding: '10px 0 0',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            background: PicColor.White,
          }}
        >
          <PicText size={PicFontSize.Lg} weight={PicFontWeight.Medium}>
            Check Added/Removed Tags
          </PicText>
          <PicDivider marginTop="12px" marginBottom="0" />
        </div>
        <div
          style={{
            flexGrow: 1,
            padding: '20px',
            overflow: 'auto',
          }}
        >
          {templateBulkTaggingEntries.length === 0 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <PicText>You have no changes yet.</PicText>
            </div>
          )}
          {templateBulkTaggingEntries.map(([, { templateTag, addedTemplates, removedTemplates }], index) => (
            <div key={templateTag.getId()} style={{ display: 'flex', flexFlow: 'column' }}>
              {addedTemplates.length > 0 && (
                <div style={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexFlow: 'row', gap: '8px', alignItems: 'center' }}>
                      <PicText>Added</PicText>
                      <PicTag copy={templateTag.getName()} color={PicColor.Teal500} />
                      <PicText>to</PicText>
                    </div>
                    <PicLink
                      copy="Revert"
                      onClick={() => {
                        onRevertAddTemplateTags(
                          templateTag.getId(),
                          addedTemplates.map((template) => template.getId()),
                        );
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(5, 1fr)',
                      gap: '8px',
                    }}
                  >
                    {addedTemplates.map((template) => (
                      <InTagTemplateCard key={template.getId()} template={template} isLink />
                    ))}
                  </div>
                </div>
              )}
              {addedTemplates.length > 0 && removedTemplates.length > 0 && (
                <PicDivider marginTop="12px" marginBottom="12px" />
              )}
              {removedTemplates.length > 0 && (
                <div style={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexFlow: 'row', gap: '8px', alignItems: 'center' }}>
                      <PicText>Removed</PicText>
                      <PicTag copy={templateTag.getName()} color={PicColor.Red500} />
                      <PicText>from</PicText>
                    </div>
                    <PicLink
                      copy="Revert"
                      onClick={() => {
                        onRevertRemoveTemplateTags(
                          templateTag.getId(),
                          removedTemplates.map((template) => template.getId()),
                        );
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(5, 1fr)',
                      gap: '8px',
                    }}
                  >
                    {removedTemplates.map((template) => (
                      <InTagTemplateCard key={template.getId()} template={template} isLink />
                    ))}
                  </div>
                </div>
              )}
              {index < templateBulkTaggingEntries.length - 1 && <PicDivider marginTop="12px" marginBottom="12px" />}
            </div>
          ))}
        </div>
      </div>
    </PicModal>
  );
}
