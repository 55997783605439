import { AppVersionVo } from '../models/app-version-vo';
import { SingleTemplateModel } from '../models/single-template-model';
import { TemplateSizeCateoryEnum } from '../models/template-size-category-enum';

export type SingleTemplateDto = {
  id: number;
  author: string;
  uploader_info: string;
  source_url: string;
  is_vip: boolean;
  is_animated: boolean;
  image_url: string;
  animated_image_url: string;
  size_category: TemplateSizeCateoryEnum;
  slots_count: number;
  created_at: string;
  updated_at: string;
  is_multipage: boolean;
  is_adjustable: boolean;
  creator_id: number;
  lower_suitable_photos_count: number | null;
  upper_suitable_photos_count: number | null;
  labels: string[];
  minimal_required_version_ios?: number[];
  minimal_required_version_android?: number[];
  template_tag_ids: number[];
  collage_struct: any;
};

export const parseSingleTemplateDto = (dto: SingleTemplateDto): SingleTemplateModel => {
  return SingleTemplateModel.create(
    dto.id,
    dto.author,
    dto.uploader_info,
    dto.source_url,
    dto.is_vip,
    dto.is_animated,
    dto.image_url,
    dto.animated_image_url,
    dto.size_category,
    dto.slots_count,
    dto.created_at,
    dto.updated_at,
    dto.is_multipage,
    dto.is_adjustable,
    dto.creator_id,
    dto.lower_suitable_photos_count,
    dto.upper_suitable_photos_count,
    dto.labels,
    dto.minimal_required_version_ios
      ? AppVersionVo.create(
          dto.minimal_required_version_ios[0] || 0,
          dto.minimal_required_version_ios[1] || 0,
          dto.minimal_required_version_ios[2] || 0,
        )
      : null,
    dto.minimal_required_version_android
      ? AppVersionVo.create(
          dto.minimal_required_version_android[0] || 0,
          dto.minimal_required_version_android[1] || 0,
          dto.minimal_required_version_android[2] || 0,
        )
      : null,
    dto.template_tag_ids,
    dto.collage_struct,
  );
};
